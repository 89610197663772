import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './IncidentReportPage.css';

function IncidentReportPage() {
  const [formData, setFormData] = useState({
    officer: '',
    colleagues: '',
    incidentTime: '',
    incidentDescription: '',
    actionsTaken: '',
    witnesses: '',
    evidence: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://tarp-pd.de:3001/incident-reports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Einsatzbericht erfolgreich hinzugefügt.');
        navigate('/swat-fbi');
      } else {
        console.error('Fehler beim Hinzufügen des Einsatzberichts.');
      }
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Einsatzberichts:', error);
    }
  };

  return (
    <div className="incident-report-page">
      <h1>Einsatzbericht erstellen</h1>
      <form onSubmit={handleSubmit} className="incident-report-form">
        <div>
          <label htmlFor="officer">Sachbearbeiter:</label>
          <input
            type="text"
            id="officer"
            name="officer"
            value={formData.officer}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="colleagues">Beteiligte Kollegen (DN):</label>
          <input
            type="text"
            id="colleagues"
            name="colleagues"
            value={formData.colleagues}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="incidentTime">Vorfallszeit:</label>
          <input
            type="datetime-local"
            id="incidentTime"
            name="incidentTime"
            value={formData.incidentTime}
            onChange={handleChange}
            required
          />
        </div>
        <div className="full-width">
          <label htmlFor="incidentDescription">Vorfallsbeschreibung:</label>
          <textarea
            id="incidentDescription"
            name="incidentDescription"
            value={formData.incidentDescription}
            onChange={handleChange}
            rows="5"
            required
          />
        </div>
        <div className="full-width">
          <label htmlFor="actionsTaken">Ergriffene Maßnahmen:</label>
          <textarea
            id="actionsTaken"
            name="actionsTaken"
            value={formData.actionsTaken}
            onChange={handleChange}
            rows="5"
            required
          />
        </div>
        <div>
          <label htmlFor="witnesses">Zeugen:</label>
          <input
            type="text"
            id="witnesses"
            name="witnesses"
            value={formData.witnesses}
            onChange={handleChange}
            required
          />
        </div>
        <div className="full-width">
          <label htmlFor="evidence">Beweise:</label>
          <textarea
            id="evidence"
            name="evidence"
            value={formData.evidence}
            onChange={handleChange}
            rows="5"
            required
          />
        </div>
        <div className="form-buttons">
          <button type="submit">Erstellen</button>
          <button type="button" className="cancel-button" onClick={() => navigate(-1)}>Abbrechen</button>
        </div>
      </form>
    </div>
  );
}

export default IncidentReportPage;