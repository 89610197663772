import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faFloppyDisk, faMagnifyingGlass, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PDLogo from './uploads/PD_Logo.png'; // Importiere das Logo
import {jsPDF} from 'jspdf';

import './RecordDetailPage.css';

const RecordDetailPage = () => {
  const [hasAccess, setHasAccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    officer: '',
    colleagues: '',
    suspect: '',
    witnesses: '',
    incidentTime: '',
    startTime: '',
    incidentDescription: '',
    evidence: '',
    suspectStatement: '',
    witnessStatements: '',
    crimes: [],
    fine: 0,
    jailTime: 0,
    status: 'open'
  });

  const [crimes, setCrimes] = useState([
    { paragraph: '§123', count: 0, description: 'Diebstahl', fine: 500, jailTime: 6, withdrawal: 'Nein' },
    { paragraph: '§223', count: 0, description: 'Körperverletzung', fine: 1000, jailTime: 12, withdrawal: 'Nein' },
    { paragraph: '§242', count: 0, description: 'Raub', fine: 2000, jailTime: 24, withdrawal: 'Ja' },
    { paragraph: '§263', count: 0, description: 'Betrug', fine: 1500, jailTime: 18, withdrawal: 'Nein' },
    { paragraph: '§315c', count: 0, description: 'Gefährdung des Straßenverkehrs', fine: 800, jailTime: 6, withdrawal: 'Ja' },
    { paragraph: '§306', count: 0, description: 'Brandstiftung', fine: 2500, jailTime: 36, withdrawal: 'Ja' },
    { paragraph: '§177', count: 0, description: 'Sexueller Übergriff', fine: 3000, jailTime: 48, withdrawal: 'Ja' },
    { paragraph: '§239', count: 0, description: 'Freiheitsberaubung', fine: 1200, jailTime: 12, withdrawal: 'Nein' },
    { paragraph: '§211', count: 0, description: 'Mord', fine: 5000, jailTime: 120, withdrawal: 'Ja' },
    { paragraph: '§240', count: 0, description: 'Nötigung', fine: 700, jailTime: 6, withdrawal: 'Nein' },
    { paragraph: '§267', count: 0, description: 'Urkundenfälschung', fine: 900, jailTime: 9, withdrawal: 'Nein' },
    { paragraph: '§263a', count: 0, description: 'Computerbetrug', fine: 1300, jailTime: 15, withdrawal: 'Nein' },
    { paragraph: '§244', count: 0, description: 'Schwerer Diebstahl', fine: 1800, jailTime: 18, withdrawal: 'Nein' },
    { paragraph: '§249', count: 0, description: 'Raubüberfall', fine: 2200, jailTime: 30, withdrawal: 'Ja' },
    { paragraph: '§252', count: 0, description: 'Räuberischer Diebstahl', fine: 2000, jailTime: 24, withdrawal: 'Ja' },
    { paragraph: '§253', count: 0, description: 'Erpressung', fine: 1600, jailTime: 20, withdrawal: 'Ja' },
    { paragraph: '§259', count: 0, description: 'Hehlerei', fine: 1100, jailTime: 12, withdrawal: 'Nein' },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 5;

  const [mitarbeiter, setMitarbeiter] = useState({}); // Benutzerzustand
  const [user, setUser] = useState({}); // Benutzerzustand

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchAccess = async () => {
      if (!user.id) return; // Warten, bis die userId verfügbar ist

      try {
        const response = await fetch('https://tarp-pd.de:3001/check-access-records', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }), // Benutzer-ID senden
        });

        if (!response.ok) {
          throw new Error('Fehler beim Überprüfen des Zugriffs.');
        }

        const data = await response.json();
        setHasAccess(data.hasAccess); // Setze den Zugriff
      } catch (error) {
        console.error('Fehler:', error);
      } finally {
        setLoading(false); // Ladezustand beenden
      }
    };

    fetchAccess();
  }, [user.id]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/user', {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();
        if (data.user) {
          setUser(data.user);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
  
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchRecordCreator = async () => {
      try {
        const response = await fetch(`https://tarp-pd.de:3001/record-creator/${id}`, {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();

        if (data.userId && data.accusedName) {
          const creatorUserId = data.userId;
          const accusedName = data.accusedName;
  
          // Setze den accusedName im Formular
          setFormData((prevFormData) => ({
            ...prevFormData,
            suspect: accusedName,
          }));
  
          // Hole den Mitarbeiter basierend auf der userId des Erstellers
          fetchMitarbeiter(creatorUserId);
        }
      } catch (error) {
        console.error('Error fetching record creator:', error);
      }
    };
  
    const fetchMitarbeiter = async (creatorUserId) => {
      try {
        const response = await fetch(`https://tarp-pd.de:3001/mitarbeiter/${creatorUserId}`, {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();

        if (data.mitarbeiter) {
          setMitarbeiter(data.mitarbeiter);
          setFormData((prevFormData) => ({
            ...prevFormData,
            officer: data.mitarbeiter.name,
          }));
        }
      } catch (error) {
        console.error('Error fetching mitarbeiter:', error);
      }
    };
  
    fetchRecordCreator();
  }, [id]);

  useEffect(() => {
    const fetchRecordDetail = async () => {
      try {
        const response = await fetch(`https://tarp-pd.de:3001/record-detail/${id}`, {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();
        if (data) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            colleagues: data.colleaguesDN || '',
            incidentTime: formatDateTime(data.incidentTime) || '',
            startTime: formatDateTime(data.startTime) || '',
            witnessStatements: data.witnessStatement || '',
            witnesses: data.witnesses || '',
            incidentDescription: data.incidentDescription || '',
            evidence: data.evidence || '',
            suspectStatement: data.suspectStatement || '',
            crimes: data.crimes ? data.crimes.split(', ').map(crime => {
              const [paragraph, description] = crime.split(': ');
              const [paragraphText, countText] = paragraph.split(' (');
              const count = parseInt(countText);
              return { paragraph: paragraphText, count, description };
            }) : [],
            date: data.date || '',
            lastEditor: data.lastEditor || '',
            status: data.status // Setze den Status
          }));
        }
      } catch (error) {
        console.error('Error fetching record detail:', error);
      }
    };
  
    fetchRecordDetail();
  }, [id]);

  const handleSave = async () => {
    try {
      // Filtere die crimes, die mindestens eine 1 im count-Feld haben
      const filteredCrimes = formData.crimes.filter(crime => crime.count >= 1);
      console.log('Filtered Crimes:', filteredCrimes); // Debugging: Zeige die gefilterten crimes an
  
      // Erstelle einen Textblock aus den gefilterten crimes
      const crimesTextBlock = filteredCrimes.map(crime => `${crime.paragraph} (${crime.count}x): ${crime.description}`).join(', ');
      console.log('Crimes Text Block:', crimesTextBlock); // Debugging: Zeige den Textblock an
  
      // Füge den Textblock zum Formular hinzu
      const formDataWithCrimes = {
        ...formData,
        crimesTextBlock,
      };
  
      // Wenn kein Eintrag existiert, erstelle einen neuen Eintrag
      const response = await fetch('https://tarp-pd.de:3001/save-record', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        userId: mitarbeiter.userId, // Benutzer-ID aus dem aktuellen Benutzerzustand
        recordId: id, // Behalte die ID bei
        colleaguesDN: formData.colleagues,
        accusedName: formData.suspect,
        witnesses: formData.witnesses,
        incidentTime: formData.incidentTime,
        startTime: formData.startTime,
        incidentDescription: formData.incidentDescription,
        evidence: formData.evidence,
        suspectStatement: formData.suspectStatement,
        witnessStatement: formData.witnessStatements,
        crimes: formDataWithCrimes.crimesTextBlock, // Sende den Textblock
        date: new Date().toISOString().split('T')[0], // Nur das Datum
        lastEditor: user.displayName, // Name des Benutzers
        status: formData.status, // Sende den Status
      }),
      credentials: 'include' // Send cookies with the request
    });
      const data = await response.json();
      console.log('Strafakte erfolgreich gespeichert:', data.message);
      console.log('Status', formData.status);
    } catch (error) {
      console.error('Fehler beim Speichern der Strafakte:', error);
    }
  };
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSave();
  };

  const handleCrimeChange = (index, field, value) => {
    const updatedCrimes = [...crimes];
    updatedCrimes[index][field] = value;
    setCrimes(updatedCrimes);
    setFormData({ ...formData, crimes: updatedCrimes });
    console.log('Updated Crimes:', updatedCrimes); // Debugging: Zeige die aktualisierten crimes an
  };

  const calculateFine = () => {
    let totalFine = 0;
    let totalJailTime = 0;
    crimes.forEach(crime => {
      totalFine += crime.count * crime.fine;
      totalJailTime += crime.count * crime.jailTime;
    });
    setFormData({ ...formData, fine: totalFine, jailTime: totalJailTime });
  };

  const filteredCrimes = crimes.filter(crime =>
    crime.paragraph.toLowerCase().includes(searchTerm.toLowerCase()) ||
    crime.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCrimes.slice(indexOfFirstItem, indexOfLastItem);

  const nextPage = () => {
    if (currentPage < Math.ceil(filteredCrimes.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const exportToPDF = async () => {
    const doc = new jsPDF();
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    let currentY = 5;
  
    // Füge den Header hinzu
    const logoWidth = 20; // Breite des Logos
    const logoHeight = 20; // Höhe des Logos
    const headerText = 'Tanoa Police Department';
    const headerTextWidth = doc.getTextWidth(headerText);
  
    // Berechne die Positionen
    const totalWidth = logoWidth + headerTextWidth + 5; // 5 ist der Abstand zwischen Logo und Text
    const startX = (pageWidth - totalWidth) / 2;
  
    // Füge das Logo hinzu
    doc.addImage(PDLogo, 'PNG', startX, currentY, logoWidth, logoHeight);
  
    // Füge den Text hinzu
    doc.setFontSize(16);
    doc.setFont('helvetica', 'bold');
    doc.text(headerText, startX + logoWidth + 5, currentY + logoHeight / 2 + 5);
  
    currentY += logoHeight + 5;
  
    // Trennlinie
    doc.line(10, currentY, pageWidth - 10, currentY);
    currentY += 10;
  
    // Füge den Titel hinzu
    doc.setFontSize(18);
    doc.text(`Strafakte #${id}`, pageWidth / 2, currentY, { align: 'center' });
    currentY += 5;
  
    // Füge die formData hinzu
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Beschuldigter: ${formData.suspect}`, 10, currentY);
    doc.text(`Vorfallszeit: ${formatDateTime(formData.incidentTime)}`, pageWidth - 10, currentY, { align: 'right' });
    currentY += 5;
  
    // Trennlinie
    doc.line(10, currentY, pageWidth - 10, currentY);
    currentY += 5;
  
    // Allgemeine Informationen
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Allgemeine Informationen', 10, currentY);
    currentY += 5;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(`Beschreibung des Vorfalls:`, 10, currentY);
    currentY += 5;
    const incidentDescriptionLines = doc.splitTextToSize(formData.incidentDescription, pageWidth - 20);
    incidentDescriptionLines.forEach(line => {
      if (currentY + 10 > pageHeight) {
        doc.addPage();
        currentY = 5;
      }
      doc.text(line, 10, currentY);
      currentY += 5;
    });
  
   // Beweise
doc.setFontSize(14);
doc.setFont('helvetica', 'bold');
doc.text(`Beweise:`, 10, currentY);
currentY += 5;
doc.setFontSize(12);
doc.setFont('helvetica', 'normal');
const evidenceLines = doc.splitTextToSize(formData.evidence, pageWidth - 20);
evidenceLines.forEach(line => {
  if (currentY + 10 > pageHeight) {
    doc.addPage();
    currentY = 10;
  }
  doc.text(line, 10, currentY);
  currentY += 5;
});
  
    // Trennlinie
    if (currentY + 10 > pageHeight) {
      doc.addPage();
      currentY = 5;
    }
    doc.line(10, currentY, pageWidth - 10, currentY);
    currentY += 5;
  
    // Straftaten
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Straftaten', 10, currentY);
    currentY += 5;
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    const filteredCrimes = formData.crimes.filter(crime => crime.count >= 1);
    const crimesTextBlock = filteredCrimes.map(crime => `${crime.paragraph} (${crime.count}x): ${crime.description}`).join(', ');
    const crimesLines = doc.splitTextToSize(crimesTextBlock, pageWidth - 20);
    crimesLines.forEach(line => {
      if (currentY + 10 > pageHeight) {
        doc.addPage();
        currentY = 5;
      }
      doc.text(line, 10, currentY);
      currentY += 5;
    });
  
    // Trennlinie
    if (currentY + 10 > pageHeight) {
      doc.addPage();
      currentY = 5;
    }
    doc.line(10, currentY, pageWidth - 10, currentY);
    currentY += 5;
  
    // Footer
    doc.setFontSize(10);
    doc.setFont('helvetica', 'italic');
    doc.text('Dieses Dokument wurde maschinell erstellt und ist ohne Unterschrift gültig.', pageWidth / 2, pageHeight - 10, { align: 'center' });
  
    // Konvertiere die PDF in ein Blob
    const pdfBlob = doc.output('blob');
  
    // Sende die PDF-Daten an den Server
    const uploadFormData = new FormData();
    uploadFormData.append('pdf', pdfBlob, `strafakte_${id}.pdf`);
  
    try {
      const response = await fetch('https://tarp-pd.de:3001/upload-pdf', {
        method: 'POST',
        body: uploadFormData
      });
  
      if (response.ok) {
        console.log('PDF erfolgreich auf dem Server gespeichert');
        // Öffne den Link zur hochgeladenen PDF
        window.open(`https://tarp-pd.de:3001/api/pdf/strafakte_${id}.pdf`, '_blank');
      } else {
        console.error('Fehler beim Speichern der PDF auf dem Server');
      }
    } catch (error) {
      console.error('Fehler beim Hochladen der PDF:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!hasAccess) {
    return <div>Zugriff verweigert</div>;
  }

  return (
    <div className="record-detail-page">
      <h1>Strafakte #{id}</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="officer">Sachbearbeiter:</label>
          <input
            type="text"
            id="officer"
            value={formData.officer}
            readOnly // Macht das Feld nicht änderbar
          />
        </div>
        <div>
          <label htmlFor="colleagues">Beteiligte Kollegen (DN):</label>
          <input
            type="text"
            id="colleagues"
            value={formData.colleagues}
            onChange={(e) => setFormData({ ...formData, colleagues: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="suspect">Beschuldigter:</label>
          <input
            type="text"
            id="suspect"
            value={formData.suspect}
            readOnly // Macht das Feld nicht änderbar
          />
        </div>
        <div>
          <label htmlFor="witnesses">Zeugen:</label>
          <input
            type="text"
            id="witnesses"
            value={formData.witnesses}
            onChange={(e) => setFormData({ ...formData, witnesses: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="incidentTime">Vorfallszeit:</label>
          <input
            type="datetime-local"
            id="incidentTime"
            value={formData.incidentTime}
            onChange={(e) => setFormData({ ...formData, incidentTime: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="startTime">Bearbeitungsstart:</label>
          <input
            type="datetime-local"
            id="startTime"
            value={formData.startTime}
            onChange={(e) => setFormData({ ...formData, startTime: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="incidentDescription">Vorfallsbeschreibung:</label>
          <textarea
            id="incidentDescription"
            value={formData.incidentDescription}
            onChange={(e) => setFormData({ ...formData, incidentDescription: e.target.value })}
            rows="5"
            cols="100"
          />
        </div>
        <div>
          <label htmlFor="evidence">Beweise:</label>
          <textarea
            id="evidence"
            value={formData.evidence}
            onChange={(e) => setFormData({ ...formData, evidence: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="suspectStatement">Aussage des Beschuldigten:</label>
          <textarea
            id="suspectStatement"
            value={formData.suspectStatement}
            onChange={(e) => setFormData({ ...formData, suspectStatement: e.target.value })}
            rows="5"
            cols="100"
          />
        </div>
        <div>
          <label htmlFor="witnessStatements">Aussagen der Zeugen:</label>
          <textarea
            id="witnessStatements"
            value={formData.witnessStatements}
            onChange={(e) => setFormData({ ...formData, witnessStatements: e.target.value })}
            rows="5"
            cols="100"
          />
        </div>
        <div>
          <label>Begangene Straftaten:</label>
          <div className="crimes-table-container">
            <table className="crimes-table">
              <div className="search-container">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <input
                  type="text"
                  placeholder="Suche nach Paragraph oder Straftatbestand"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <thead>
                <tr>
                  <th>Paragraph</th>
                  <th>Anzahl</th>
                  <th>Straftatbestand</th>
                  <th>Bußgeld</th>
                  <th>Haftstrafe</th>
                  <th>Entzug</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((crime, index) => (
                  <tr key={index}>
                    <td>{crime.paragraph}</td>
                    <td><input type="number" name="count" value={crime.count} onChange={(e) => handleCrimeChange(indexOfFirstItem + index, 'count', parseInt(e.target.value) || 0)} /></td>
                    <td>{crime.description}</td>
                    <td>{crime.fine} €</td>
                    <td>{crime.jailTime} Monate</td>
                    <td>{crime.withdrawal}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination">
            <button type="button" onClick={prevPage} disabled={currentPage === 1}>&lt; Zurück</button>
            <span>Seite {currentPage} von {Math.ceil(filteredCrimes.length / itemsPerPage)}</span>
            <button type="button" onClick={nextPage} disabled={currentPage === Math.ceil(filteredCrimes.length / itemsPerPage)}>Weiter &gt;</button>
          </div>
        </div>
        <div className="fine-calculator">
          <label>Bußgeld Rechner:</label>
          <button type="button" onClick={calculateFine}>Berechne Strafe</button>
          <p>Geldstrafe: {formData.fine} €</p>
          <p>Haftstrafe: {formData.jailTime} Monate</p>
        </div>
        <div>
          <label htmlFor="status">Status:</label>
          <select
            id="status"
            value={formData.status || 'open'}
            onChange={(e) => setFormData({ ...formData, status: e.target.value })}
          >
            <option value="open">Offen</option>
            <option value="in_progress">In Bearbeitung</option>
            <option value="manhunt">Fahndung</option>
            <option value="closed">Geschlossen</option>
          </select>
        </div>
        <div className="form-buttons">
          <button onClick={handleSave}>
            <FontAwesomeIcon icon={faFloppyDisk} /> Speichern
          </button>
          <Link to="/criminal-records" className="cancel-button">
            
              <FontAwesomeIcon icon={faArrowLeft} /> Zurück
            
          </Link>
          <button onClick={exportToPDF}>
            <FontAwesomeIcon icon={faFilePdf} />Export to PDF
            </button>
        </div>
      </form>
    </div>
  );
};

export default RecordDetailPage;