import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faGavel, faMapMarkedAlt, faClipboardList, faUsers, faFolderOpen, faMap } from '@fortawesome/free-solid-svg-icons';
import './SwatFbiPage.css';

function SwatFbiPage() {
  const [user, setUser] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('https://tarp-pd.de:3001/user', { credentials: 'include' })
      .then((res) => res.json())
      .then((data) => {
        if (data.user) {
          setUser(data.user);
        }
      });
      // Fetch employee data
    fetch('https://tarp-pd.de:3001/mitarbeiter')
    .then((res) => res.json())
    .then((data) => {
      setEmployeeData(data);
      // processEmployeeData(data); // Entfernen Sie diesen Aufruf, wenn die Funktion nicht benötigt wird
    });
  }, []);

  return (
    <div className="dashboard-layout">
      <Sidebar user={user} setUser={setUser} />
      <div className="content">
        <div className="options">
          <div className="option-card" onClick={() => navigate('/search-warrant')}>
            <FontAwesomeIcon icon={faFileAlt} size="3x" />
            <h2>Durchsuchungsbeschluss beantragen</h2>
            <p>Beantragen Sie hier einen Durchsuchungsbeschluss.</p>
            <button>Beantragen</button>
          </div>
          <div className="option-card" onClick={() => navigate('/arrest-warrant')}>
            <FontAwesomeIcon icon={faGavel} size="3x" />
            <h2>Haftbefehl beantragen</h2>
            <p>Beantragen Sie hier einen Haftbefehl.</p>
            <button>Beantragen</button>
          </div>
          <div className="option-card" onClick={() => navigate('/interactive-map')}>
            <FontAwesomeIcon icon={faMapMarkedAlt} size="3x" />
            <h2>Interaktive Karte öffnen</h2>
            <p>Öffnen Sie die interaktive Karte der Arma 3 Karte Tanoa.</p>
            <button>Karte öffnen</button>
          </div>
          <div className="option-card" onClick={() => navigate('/incident-report')}>
            <FontAwesomeIcon icon={faClipboardList} size="3x" />
            <h2>Einsatzbericht erstellen</h2>
            <p>Erstellen Sie hier einen Einsatzbericht.</p>
            <button>Erstellen</button>
          </div>
          <div className="option-card" onClick={() => navigate('/group-files')}>
            <FontAwesomeIcon icon={faFolderOpen} size="3x" />
            <h2>Gruppierungsakten öffnen</h2>
            <p>Öffnen Sie die Gruppierungsakten.</p>
            <button>Öffnen</button>
          </div>
          <div className="option-card" onClick={() => navigate('/group-reports')}>
            <FontAwesomeIcon icon={faMap} size="3x" />
            <h2>Gruppierungsberichte erstellen</h2>
            <p>Erstellen Sie hier einen Gruppierungsbericht.</p>
            <button>Erstellen</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwatFbiPage;