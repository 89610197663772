import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ArrestWarrant.css';

function ArrestWarrantPage() {
  const [formData, setFormData] = useState({
    officer: '',
    colleagues: '',
    suspect: '',
    witnesses: '',
    incidentTime: '',
    startTime: '',
    incidentDescription: '',
    evidence: '',
    suspectStatement: '',
    witnessStatements: '',
    crimes: [],
    fine: 0,
    jailTime: 0,
    status: 'open'
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data and set officer name
    fetch('https://tarp-pd.de:3001/user', { credentials: 'include' })
      .then((res) => res.json())
      .then((data) => {
        if (data.user) {
          setFormData((prevFormData) => ({
            ...prevFormData,
            officer: data.user.displayName,
          }));
        }
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://tarp-pd.de:3001/arrest-warrants', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Haftbefehl erfolgreich hinzugefügt.');
        navigate('/swat-fbi');
      } else {
        console.error('Fehler beim Hinzufügen des Haftbefehls.');
      }
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Haftbefehls:', error);
    }
  };

  return (
    <div className="arrest-warrant-page">
      <h1>Haftbefehl beantragen</h1>
      <form onSubmit={handleSubmit} className="arrest-warrant-form">
        <div>
          <label htmlFor="officer">Sachbearbeiter:</label>
          <input
            type="text"
            id="officer"
            name="officer"
            value={formData.officer}
            readOnly
          />
        </div>
        <div>
          <label htmlFor="colleagues">Beteiligte Kollegen (DN):</label>
          <input
            type="text"
            id="colleagues"
            name="colleagues"
            value={formData.colleagues}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="suspect">Beschuldigter:</label>
          <input
            type="text"
            id="suspect"
            name="suspect"
            value={formData.suspect}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="witnesses">Zeugen:</label>
          <input
            type="text"
            id="witnesses"
            name="witnesses"
            value={formData.witnesses}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="incidentTime">Vorfallszeit:</label>
          <input
            type="datetime-local"
            id="incidentTime"
            name="incidentTime"
            value={formData.incidentTime}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="startTime">Bearbeitungsstart:</label>
          <input
            type="datetime-local"
            id="startTime"
            name="startTime"
            value={formData.startTime}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="incidentDescription">Vorfallsbeschreibung:</label>
          <textarea
            id="incidentDescription"
            name="incidentDescription"
            value={formData.incidentDescription}
            onChange={handleChange}
            rows="5"
            cols="100"
          />
        </div>
        <div>
          <label htmlFor="evidence">Beweise:</label>
          <textarea
            id="evidence"
            name="evidence"
            value={formData.evidence}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor="suspectStatement">Aussage des Beschuldigten:</label>
          <textarea
            id="suspectStatement"
            name="suspectStatement"
            value={formData.suspectStatement}
            onChange={handleChange}
            rows="5"
            cols="100"
          />
        </div>
        <div>
          <label htmlFor="witnessStatements">Aussagen der Zeugen:</label>
          <textarea
            id="witnessStatements"
            name="witnessStatements"
            value={formData.witnessStatements}
            onChange={handleChange}
            rows="5"
            cols="100"
          />
        </div>
        <div className="form-buttons">
          <button type="submit">Beantragen</button>
          <button type="button" className="cancel-button" onClick={() => navigate(-1)}>Abbrechen</button>
        </div>
      </form>
    </div>
  );
}

export default ArrestWarrantPage;