import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './ChatPage.css'; // Import the CSS file
import Sidebar from './Sidebar';
import './Sidebar.css'; // Import the CSS file

const ChatPage = () => {
  const { applicationId } = useParams();
  const [chat, setChat] = useState([]);
  const [message, setMessage] = useState('');
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [applicantName, setApplicantName] = useState(''); // Neuer Zustand für den Namen des Bewerbers
  const [user, setUser] = useState(null); // Zustand für den Benutzer
  const [status, setStatus] = useState(''); // Zustand für den Status
  const [newStatus, setNewStatus] = useState(''); // Zustand für den neuen Status
  const [statusMessage, setStatusMessage] = useState(''); // Zustand für die Statusmeldung
  const [userGroup, setUserGroup] = useState(''); // Zustand für die Benutzergruppe

  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await fetch(`https://tarp-pd.de:3001/chat/${applicationId}`, {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();

        if (data.success) {
          setChat(data.chat);
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
        }
      } catch (error) {
        console.error('Error fetching chat:', error);
        setIsAuthorized(false);
      }
    };

    const fetchApplicantName = async () => {
      try {
        const response = await fetch(`https://tarp-pd.de:3001/application/${applicationId}`, {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();

        if (data.success) {
          setApplicantName(data.ingameName);
          setStatus(data.status); // Setze den Status
        }
      } catch (error) {
        console.error('Error fetching applicant name:', error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/user', {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();

        if (data.user) {
          setUser(data.user);
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    const fetchUserGroup = async (userId) => {
      try {
        const response = await fetch(`https://tarp-pd.de:3001/user-group/${userId}`, {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();
        if (data.userGroup) {
          setUserGroup(data.userGroup);
        }
      } catch (error) {
        console.error('Error fetching user group:', error);
      }
    };

    fetchChat();
    fetchApplicantName();
    fetchUser();
  }, [applicationId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://tarp-pd.de:3001/chat/${applicationId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
        credentials: 'include' // Send cookies with the request
      });
      const data = await response.json();
      if (data.success) {
        setChat([...chat, { id: Date.now(), message, name: data.ingameName }]); // Update chat with the new message
        setMessage(''); // Clear the input field
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleStatusChange = (newStatus) => {
    setNewStatus(newStatus); // Setze den neuen Status
  };

  const handleConfirmStatusChange = async () => {
    try {
      const response = await fetch(`https://tarp-pd.de:3001/application/${applicationId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
        credentials: 'include' // Send cookies with the request
      });
      const data = await response.json();
      if (data.success) {
        setStatus(newStatus); // Update the status
        setNewStatus(''); // Clear the new status
        setStatusMessage('Status erfolgreich geändert'); // Setze die Statusmeldung
        setTimeout(() => setStatusMessage(''), 3000); // Lösche die Statusmeldung nach 3 Sekunden
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
    <div className="page-container">
      <Sidebar user={user} setUser={setUser} className="sidebar" />
      <div className="chat-page">
        <div className="chat-container">
          <h1 className="chat-header">Bewerbung von {applicantName}</h1>
          {['Administrative Leitung', 'Abteilungsleitung', 'Ausbilder'].includes(userGroup) && (
            <div className="status-container">
              <label htmlFor="status">Status:</label>
              <select
                id="status"
                value={newStatus || status}
                onChange={(e) => handleStatusChange(e.target.value)}
                className="status-dropdown"
              >
                <option value="">Bitte Option auswählen</option>
                <option value="0">Abgelehnt</option>
                <option value="1">Angenommen</option>
              </select>
              {newStatus && (
                <button onClick={handleConfirmStatusChange} className="confirm-status-button">
                  Status bestätigen
                </button>
              )}
            </div>
          )}
          {statusMessage && <p className="status-message">{statusMessage}</p>}
          {isAuthorized ? (
            <>
              <div className="chat-box">
                <ul className="chat-messages">
                  {chat.map((msg) => (
                    <li key={msg.id} className="chat-message">
                      <strong>{msg.name}:</strong> {msg.message}
                    </li>
                  ))}
                </ul>
              </div>
              <form className="chat-form" onSubmit={handleSendMessage}>
                <input
                  type="text"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Type your message"
                  className="chat-input"
                />
                <button type="submit" className="chat-send-button">Send</button>
              </form>
            </>
          ) : (
            <p className="unauthorized-message">Sie sind nicht berechtigt, diesen Chat zu sehen.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPage;