import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Apply from "./components/Apply";
import TPD from "./components/TPD";
import Profile from "./components/Profile";
import Training from './components/Training';
import ApplicationsPage from './components/ApplicationPage';
import ChatPage from './components/ChatPage';
import CriminalRecordsPage from './components/CriminalRecordsPage';
import RecordDetailPage from './components/RecordDetailPage';
import SwatFbiPage from './components/SwatFbiPage';
import SearchWarrantPage from './components/SearchWarrant';
import ArrestWarrantPage from './components/ArrestWarrant';
import InteractiveMap from './components/InteractiveMap';
import IncidentReportPage from './components/IncidentReportPage';
import GroupFilesPage from './components/GroupFiles';
import GroupReportsPage from './components/GroupRecords';
import EditGroupReportPage from './components/EditGroupRecords';
import WarrantPage from './components/WarrantPage';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Benutzerinformationen abrufen
    fetch('https://tarp-pd.de:3001/user', { credentials: 'include' })
      .then(response => response.json())
      .then(data => setUser(data.user))
      .catch(error => console.error('Error fetching user info:', error));
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/" />} />
          <Route path="/apply" element={<Apply />} />
          <Route path="/login" element={<Login />} />
          <Route path="/TPD" element={user ? <TPD user={user} setUser={setUser} /> : <Navigate to="/" />} />
          <Route path="/mitarbeiter" element={user ? <TPD user={user} setUser={setUser} /> : <Navigate to="/" />} />
          <Route path="/trainings" element={user ? <Training user={user} setUser={setUser} /> : <Navigate to="/" />} />
          <Route path="/profile" element={user ? <Profile user={user} /> : <Navigate to="/" />} />
          <Route path="/applications" element={<ApplicationsPage user={user} setUser={setUser} />} />
          <Route path="/chat/:applicationId" element={<ChatPage />} />
          <Route path="/criminal-records" element={user ? <CriminalRecordsPage /> : <Navigate to="/" />} />
          <Route path="/record/:id" element={<RecordDetailPage />} />
          <Route path="/swat-fbi" element={user ? <SwatFbiPage /> : <Navigate to="/" />} />
          <Route path="/search-warrant" element={user ? <SearchWarrantPage /> : <Navigate to="/" />} />
          <Route path="/arrest-warrant" element={user ? <ArrestWarrantPage /> : <Navigate to="/" />} />
          <Route path="/interactive-map" element={user ? <InteractiveMap /> : <Navigate to="/" />} />
          <Route path="/incident-report" element={user ? <IncidentReportPage /> : <Navigate to="/" />} />
          <Route path="/group-files" element={user ? <GroupFilesPage /> : <Navigate to="/" />} />
          <Route path="/group-reports" element={user ? <GroupReportsPage /> : <Navigate to="/" />} />
          <Route path="/edit-group-report/:id" element={user ? <EditGroupReportPage /> : <Navigate to="/" />} />
          <Route path="/warrant-page" element={user ? <WarrantPage /> : <Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;