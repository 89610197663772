import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './GroupFiles.css';

function GroupFilesPage() {
  const [files, setFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const reportsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch group files data
    fetch('https://tarp-pd.de:3001/group-reports')
      .then((res) => res.json())
      .then((data) => {
        setFiles(data);
      });
  }, []);

  // Berechne die Einträge, die auf der aktuellen Seite angezeigt werden sollen
  const indexOfLastReport = currentPage * reportsPerPage;
  const indexOfFirstReport = indexOfLastReport - reportsPerPage;
  const currentReports = files.slice(indexOfFirstReport, indexOfLastReport);

  // Berechne die Gesamtzahl der Seiten
  const totalPages = Math.ceil(files.length / reportsPerPage);

  return (
    <div className="group-files-page">
      <div className="header">
        <button className="back-button" onClick={() => navigate('/swat-fbi')}>
          <FontAwesomeIcon icon={faArrowLeft} /> Zurück
        </button>
        <h1>Gruppierungsakten</h1>
      </div>
      <table className="reports-table">
        <thead>
          <tr>
            <th>Titel</th>
            <th>Inhalt</th>
            <th>Aktionen</th>
          </tr>
        </thead>
        <tbody>
          {currentReports.map((file) => (
            <tr key={file.id}>
              <td>{file.reportTitle}</td>
              <td>{file.reportContent}</td>
              <td>
                <button className="edit-button" onClick={() => navigate(`/edit-group-report/${file.id}`)}>
                  <FontAwesomeIcon icon={faEdit} /> Bearbeiten
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
          Vorherige Seite
        </button>
        <span>Seite {currentPage} von {totalPages}</span>
        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
          Nächste Seite
        </button>
      </div>
    </div>
  );
}

export default GroupFilesPage;