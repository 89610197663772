import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faQuestionCircle, faEnvelope, faNewspaper, faLockOpen, faCircleInfo, faBinoculars } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import './Dashboard.css';
import Sidebar from './Sidebar';
import ChipLabel from './ChipLabel';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts'; // Importiere Recharts
import SwatFbiPage from './SwatFbiPage'; // Importiere die neue Seite
import PDLogo from './uploads/PD_Logo.png'; // Importiere das Bild

function Dashboard() {
  const [user, setUser] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null); // Für den aktiven Index
  const [employeeData, setEmployeeData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const COLORS = ['#d45d55', '#c26400', '#001F5B', '#28a745', '#1f74bf', '#3f4142']; // Hier kannst du die Farben anpassen
  const [manhuntRecords, setManhuntRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State für die aktuelle Seite
  const recordsPerPage = 6; // Anzahl der Einträge pro Seite
  const navigate = useNavigate(); // useNavigate Hook für Navigation

  useEffect(() => {
    fetch('https://tarp-pd.de:3001/user', { credentials: 'include' })
      .then((res) => res.json())
      .then((data) => {
        if (data.user) {
          setUser(data.user);
        }
      });
      // Fetch employee data
    fetch('https://tarp-pd.de:3001/mitarbeiter')
    .then((res) => res.json())
    .then((data) => {
      setEmployeeData(data);
      processEmployeeData(data); // Daten verarbeiten
    });
  }, []);

  useEffect(() => {
    const fetchManhuntRecords = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/criminal-records?status=manhunt');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setManhuntRecords(data.filter(record => record.status === 'manhunt')); // Filter anwenden
      } catch (error) {
        console.error('Error fetching manhunt records:', error);
      }
    };
  
    fetchManhuntRecords();
  }, []);

  const processEmployeeData = (data) => {
    const functionCount = {};
  
    data.forEach(employee => {
      const role = employee.funktion; // Die Funktion des Mitarbeiters verwenden
      if (functionCount[role]) {
        functionCount[role] += 1; // Zähle die Anzahl der Mitarbeiter in dieser Funktion
      } else {
        functionCount[role] = 1; // Initialisiere den Zähler für diese Funktion
      }
    });
  
    // Konvertiere das Zählobjekt in ein Array für den PieChart
    const pieData = Object.entries(functionCount).map(([key, value]) => ({
      name: key,
      value: value,
    }));
  
    console.log('PieChart-Daten:', pieData); // Debugging
    setPieChartData(pieData); // Setze die PieChart-Daten
  };

  const handleChartClick = async () => {
    try {
      const response = await fetch('https://tarp-pd.de:3001/check-access-swat-fbi', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.id }),
      });

      const data = await response.json();

      if (data.hasAccess) {
        navigate('/swat-fbi'); // Navigiere zur internen Seite
      } else {
        alert('Zugriff verweigert. Sie haben keine Berechtigung, diese Seite zu betreten.');
      }
    } catch (error) {
      console.error('Fehler beim Überprüfen des Zugriffs:', error);
    }
  };

  if (!user) {
    return <h2>Loading...</h2>;
  }

  // Berechne die Einträge, die auf der aktuellen Seite angezeigt werden sollen
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = manhuntRecords.slice(indexOfFirstRecord, indexOfLastRecord);

  // Berechne die Gesamtzahl der Seiten
  const totalPages = Math.ceil(manhuntRecords.length / recordsPerPage);
  return (
    <div className="dashboard-layout">
      {/* Sidebar */}
      <Sidebar user={user} setUser={setUser} />
      <ChipLabel text="Made by YoMama" />
      {/* Main Content */}
      <div className={`main-content ${isPopupVisible ? 'blur-content' : ''}`}>
        <div className="application-header">
          <h2>Bewerbung für das TPD</h2>
          <div className="application-options">
            <select className="phase-select">
              <option value="open">Aktuelle Bewerbungsphasen</option>
            </select>
            <div className="class-info">
              <FontAwesomeIcon icon={faLockOpen} className="class-lock-icon" />
              Officer
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="class-help-icon"
                onClick={() => setPopupVisible(true)} // Öffnet das Popup
              />
            </div><button className="apply-button" onClick={() => navigate('/apply')}>
              <FontAwesomeIcon icon={faEnvelope} /> Bewerben
            </button>
            
          </div>
        </div>

        <div className="dashboard-row">
          <div className="dashboard-card">
            <h2>Polizisten</h2>
            <div className="piechart-container">
              <PieChart width={300} height={300}>
                <Pie
                  data={pieChartData}
                  cx={150}
                  cy={150}
                  innerRadius={50}
                  outerRadius={100}
                  fill="#8884d8"
                  paddingAngle={0}
                  dataKey="value"
                  animationDuration={500}
                  onMouseEnter={(data, index) => setActiveIndex(index)} // Setze den aktiven Index beim Hover
                  onMouseLeave={() => setActiveIndex(null)} // Setze den aktiven Index zurück beim Verlassen
                >
                  {pieChartData.map((entry, index) => (
                    <Cell 
                      key={`cell-${index}`} 
                      fill={COLORS[index % COLORS.length]} 
                      style={{ cursor: 'pointer' }} // Zeigt Handcursor beim Hover
                      scale={activeIndex === index ? 1.1 : 1} // Erhöht die Größe beim Hover
                    />
                  ))}
                </Pie>
                <Tooltip 
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', color: '#000' }}>
                          <strong>{payload[0].name}</strong>: {payload[0].value} Beamte
                        </div>
                      );
                    }
                    return null;
                  }} 
                  cursor={false} // Verhindert den Standard-Cursor
                />
              </PieChart>
            </div>
        
            <div className="legend">
              {pieChartData.map((entry, index) => (
                <div key={`legend-${index}`} className="legend-item">
                  <span 
                    className="legend-color" 
                    style={{ backgroundColor: COLORS[index % COLORS.length] }} 
                  />
                  <span className="legend-text">{entry.name}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="dashboard-card">
            <h2>S.W.A.T. und FBI</h2>
            <div className="swat-fbi-chart" onClick={handleChartClick}>
              <img src={PDLogo} alt="PD Logo" style={{ width: '325px', height: '325px' }} />
            </div>
          </div>
          <div className="dashboard-card">
            <h2>Fahndung</h2>
            <table className="manhunt-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Beschuldigter</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((record, index) => (
                  <tr key={record.id}>
                    <td><Link to={`/record/${record.id}`}>#{record.id}</Link></td>
                    <td>{record.accusedName}</td>
                    <td><span className="purple-badge"><FontAwesomeIcon icon={faBinoculars}/> Fahndung</span></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                Vorherige Seite
              </button>
              <span>Seite {currentPage} von {totalPages}</span>
              <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                Nächste Seite
              </button>
            </div>
          </div>
        </div>

        <div className="dashboard-news">
          <h2>
            <FontAwesomeIcon icon={faNewspaper} /> Neuigkeiten
          </h2>
          <p>Derzeit gibt es keine Neuigkeiten</p>
        </div>
      </div>

      {/* Pop-up Modal */}
      {isPopupVisible && (
        <>
          <div className="popup-overlay" onClick={() => setPopupVisible(false)}></div> {/* Schließt das Popup, wenn man außerhalb klickt */}
          <div className="popup-modal">
            <div className="popup-content">
              <h2><FontAwesomeIcon icon={faCircleInfo} /> Aktuelle Bewerbungsphase</h2>
              
              {/* Flexbox-basierte Auflistung */}
              <div className="info-row">
                <span>Status</span>
                <span className="badge active"><FontAwesomeIcon icon={faLockOpen} /> Offen</span>
              </div>

              <div className="info-row">
                <span>Zeitraum</span>
                <span className="badge">01.11.2024 - 31.01.2025</span>
              </div>

              {/* Zulässige Bewerbungstypen mit Legende */}
              <div className="info-row">
                <span>Zulässige Bewerbungstypen</span>
                <div className="application-types">
                  <span className="badge application-type">Versetzungsantrag</span>
                  <span className="badge application-type">Officer</span>
                  <span className="badge application-type">Praktikum</span>
                </div>
              </div>
              <br></br>

              <button onClick={() => setPopupVisible(false)}>Schließen</button> {/* Schließen-Button */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;