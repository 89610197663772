import React, { useState, useEffect } from 'react';
import './CriminalRecordsPage.css';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const CriminalRecordsPage = () => {
  const [user, setUser] = useState({}); // Benutzerzustand
  const [records, setRecords] = useState([]);
  const [accusedName, setAccusedName] = useState(''); // Name des Beschuldigten
  const [description, setDescription] = useState('');
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [hasAccess, setHasAccess] = useState(false); // Zustand für den Zugriff

  const [currentPage, setCurrentPage] = useState(1); // Aktuelle Seite
  const [itemsPerPage] = useState(10); // Einträge pro Seite
  const [searchTerm, setSearchTerm] = useState(''); // Suchbegriff

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/user', {
          credentials: 'include' // Send cookies with the request
        });
        const data = await response.json();

        if (data.user) {
          setUser(data.user);
          // Fetch the user's role from the humanresources table
          const hrResponse = await fetch(`https://tarp-pd.de:3001/mitarbeiter/${data.user.id}`, {
            credentials: 'include' // Send cookies with the request
          });
          const hrResponseData = await hrResponse.json();
          
          if (hrResponseData.mitarbeiter) {
            setUser(prevUser => ({ ...prevUser, role: hrResponseData.mitarbeiter.funktion }));
          }
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAccess = async () => {
      if (!user.id) return; // Warten, bis die userId verfügbar ist

      try {
        const response = await fetch('https://tarp-pd.de:3001/check-access-records', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }), // Benutzer-ID senden
        });
        if (!response.ok) {
          throw new Error('Fehler beim Überprüfen des Zugriffs.');
        }

        const data = await response.json();
        setHasAccess(data.hasAccess); // Setze den Zugriff
      } catch (error) {
        console.error('Fehler:', error);
      } finally {
        setLoading(false); // Ladezustand beenden
      }
    };

    fetchAccess();
  }, [user.id]);

  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/criminal-records');
        const data = await response.json();
        setRecords(data);
      } catch (error) {
        console.error('Error fetching records:', error);
      }
    };

    fetchRecords();
  }, []);

  const handleAddRecord = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://tarp-pd.de:3001/criminal-records', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
        userId: user.id, // Benutzer-ID aus dem aktuellen Benutzerzustand
        accusedName,
        description,
        date,
        status: 'open',
        }),
      });
      const data = await response.json();

      setMessage(data.message);
      setAccusedName('');
      setDescription('');
      setDate('');
      // Aktualisieren Sie die Liste der Strafakten
      const updatedRecords = await fetch('https://tarp-pd.de:3001/criminal-records');
      const updatedData = await updatedRecords.json();
      setRecords(updatedData);
    } catch (error) {
      console.error('Error adding record:', error);
    }
  };

  const openDeleteConfirmation = (record) => {
    setCurrentRecord(record);
    setShowConfirmation(true);
  };

  const handleDeleteRecord = async () => {
    if (!currentRecord) return;

    try {
      await fetch(`https://tarp-pd.de:3001/criminal-records/${currentRecord.id}`
        , {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: user.id }), // Benutzer-ID senden
        });
      // Aktualisieren Sie die Liste der Strafakten
      const updatedRecords = await fetch('https://tarp-pd.de:3001/criminal-records');
      const updatedData = await updatedRecords.json();
      setRecords(updatedData);
      setShowConfirmation(false);
      setCurrentRecord(null);
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Zurück zur ersten Seite bei neuer Suche
  };

  const filteredRecords = records.filter(record =>
    record.accusedName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredRecords.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredRecords.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!hasAccess) {
    return <div>Zugriff verweigert</div>;
  }

  return (
    <div className="page-container">
      <Sidebar user={user} setUser={setUser} className="sidebar" />
      <div className="criminal-records-page">
        <div className="form-container">
          <h1 className="form-header">Verwaltung von Strafakten</h1>
          <form onSubmit={handleAddRecord} className="add-record-form">
            <div>
              <label htmlFor="accusedName">Name des Beschuldigten:</label>
              <input
                type="text"
                id="accusedName"
                value={accusedName}
                onChange={(e) => setAccusedName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="description">Beschreibung:</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="date">Datum:</label>
              <input
                type="date"
                id="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="add-record-button">Strafakte hinzufügen</button>
          </form>
          {message && <p className="message">{message}</p>}
        </div>
        <div className="records-container">
          <h2 className="records-header">Bestehende Strafakten</h2>
          <input
            type="text"
            placeholder="Nach Beschuldigten suchen..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-input"
          />
          <table className="records-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name des Beschuldigten</th>
                <th>Kurzbeschreibung</th>
                <th>Datum</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((record) => (
                <tr key={record.id}>
                  <td><Link to={`/record/${record.id}`}>#{record.id}</Link></td>
                  <td>{record.accusedName}</td>
                  <td>{record.description}</td>
                  <td>{new Date(record.date).toLocaleDateString()}</td>
                  {user.role === 'Administrative Leitung' && (
                    <td>
                      <button onClick={() => openDeleteConfirmation(record)} className="delete-button">
                      <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>Vorherige Seite</button>
            <span>Seite {currentPage} von {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>Nächste Seite</button>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleDeleteRecord}
        message={`Willst du die Strafakte von ${currentRecord ? currentRecord.accusedName : ''} wirklich löschen?`}
      />
    </div>
  );
};
const ConfirmationPopup = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Bestätigung</h2>
        <p>{message}</p>
        <div className="button-group">
        <button className="confirm-button" onClick={onConfirm}>Ja</button>
        <button className="cancel-button" onClick={onClose}>Nein</button>
      </div>
      </div>
    </div>
  );
};
export default CriminalRecordsPage;