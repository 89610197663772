import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './GroupRecords.css';

function GroupReportsPage() {
  const [formData, setFormData] = useState({
    groupName: '',
    reportTitle: '',
    reportContent: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://tarp-pd.de:3001/group-reports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Gruppierungsbericht erfolgreich hinzugefügt.');
        navigate('/swat-fbi');
      } else {
        console.error('Fehler beim Hinzufügen des Gruppierungsberichts.');
      }
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Gruppierungsberichts:', error);
    }
  };

  return (
    <div className="group-reports-page">
      <h1>Gruppierungsbericht erstellen</h1>
      <form onSubmit={handleSubmit} className="group-reports-form">
        <div>
          <label htmlFor="groupName">Gruppenname:</label>
          <input
            type="text"
            id="groupName"
            name="groupName"
            value={formData.groupName}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="reportTitle">Berichtstitel:</label>
          <input
            type="text"
            id="reportTitle"
            name="reportTitle"
            value={formData.reportTitle}
            onChange={handleChange}
            required
          />
        </div>
        <div className="full-width">
          <label htmlFor="reportContent">Bericht:</label>
          <textarea
            id="reportContent"
            name="reportContent"
            value={formData.reportContent}
            onChange={handleChange}
            rows="10"
            required
          />
        </div>
        <div className="form-buttons">
          <button type="submit">Erstellen</button>
          <button type="button" className="cancel-button" onClick={() => navigate(-1)}>Abbrechen</button>
        </div>
      </form>
    </div>
  );
}

export default GroupReportsPage;