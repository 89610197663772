import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchWarrant.css';

function SearchWarrantPage() {
  const [formData, setFormData] = useState({
    officer: '',
    address: '',
    reason: '',
    date: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://tarp-pd.de:3001/search-warrants', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        console.log('Durchsuchungsbeschluss erfolgreich hinzugefügt.');
        navigate('/swat-fbi');
      } else {
        console.error('Fehler beim Hinzufügen des Durchsuchungsbeschlusses.');
      }
    } catch (error) {
      console.error('Fehler beim Hinzufügen des Durchsuchungsbeschlusses:', error);
    }
  };

  return (
    <div className="search-warrant-page">
      <h1>Durchsuchungsbeschluss beantragen</h1>
      <form onSubmit={handleSubmit} className="search-warrant-form">
        <div>
          <label htmlFor="officer">Sachbearbeiter:</label>
          <input
            type="text"
            id="officer"
            name="officer"
            value={formData.officer}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="address">Adresse:</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>
        <div className="full-width">
          <label htmlFor="reason">Sachverhalt:</label>
          <textarea
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="date">Datum:</label>
          <input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-buttons">
          <button type="submit">Beantragen</button>
          <button type="button" className="cancel-button" onClick={() => navigate(-1)}>Abbrechen</button>
        </div>
      </form>
    </div>
  );
}

export default SearchWarrantPage;