import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './WarrantPage.css';

function WarrantPage() {
  const [searchWarrants, setSearchWarrants] = useState([]);
  const [arrestWarrants, setArrestWarrants] = useState([]);
  const [selectedWarrant, setSelectedWarrant] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSearchWarrants = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/search-warrants', { credentials: 'include' });
        const data = await response.json();
        setSearchWarrants(data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Durchsuchungsbeschlüsse:', error);
      }
    };

    const fetchArrestWarrants = async () => {
      try {
        const response = await fetch('https://tarp-pd.de:3001/arrest-warrants', { credentials: 'include' });
        const data = await response.json();
        setArrestWarrants(data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Haftbefehle:', error);
      }
    };

    fetchSearchWarrants();
    fetchArrestWarrants();
  }, []);

  const handleStatusChange = async (id, type, newStatus) => {
    const url = type === 'search' ? `https://tarp-pd.de:3001/search-warrants/${id}` : `https://tarp-pd.de:3001/arrest-warrants/${id}`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });
      if (response.ok) {
        if (type === 'search') {
          setSearchWarrants((prevWarrants) =>
            prevWarrants.map((warrant) =>
              warrant.id === id ? { ...warrant, status: newStatus } : warrant
            )
          );
        } else {
          setArrestWarrants((prevWarrants) =>
            prevWarrants.map((warrant) =>
              warrant.id === id ? { ...warrant, status: newStatus } : warrant
            )
          );
        }
        setModalOpen(false);
      } else {
        console.error('Fehler beim Aktualisieren des Status.');
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Status:', error);
    }
  };

  const openModal = (warrant, type) => {
    setSelectedWarrant({ ...warrant, type });
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedWarrant(null);
    setModalOpen(false);
  };

  const handleDropdownChange = (event) => {
    const newStatus = event.target.value;
    handleStatusChange(selectedWarrant.id, selectedWarrant.type, newStatus);
  };

  return (
    <div className="warrant-page">
      <div className="warrant-tables">
        <div className="warrant-table-container">
          <h1>Beantragte Durchsuchungsbeschlüsse</h1>
          <table className="warrant-table">
            <thead>
              <tr>
                <th>Sachbearbeiter</th>
                <th>Adresse</th>
                <th>Sachverhalt</th>
                <th>Datum</th>
                <th>Status</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {searchWarrants.map((warrant) => (
                <tr key={warrant.id}>
                  <td>{warrant.officer}</td>
                  <td>{warrant.address}</td>
                  <td>{warrant.reason}</td>
                  <td>{warrant.date}</td>
                  <td>{warrant.status}</td>
                  <td>
                    <button className="edit-button" onClick={() => openModal(warrant, 'search')}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="warrant-table-container">
          <h1>Beantragte Haftbefehle</h1>
          <table className="warrant-table">
            <thead>
              <tr>
                <th>Sachbearbeiter</th>
                <th>Beteiligte Kollegen</th>
                <th>Beschuldigter</th>
                <th>Zeugen</th>
                <th>Vorfallszeit</th>
                <th>Vorfallsbeschreibung</th>
                <th>Beweise</th>
                <th>Aussage des Beschuldigten</th>
                <th>Aussagen der Zeugen</th>
                <th>Status</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              {arrestWarrants.map((warrant) => (
                <tr key={warrant.id}>
                  <td>{warrant.officer}</td>
                  <td>{warrant.colleagues}</td>
                  <td>{warrant.suspect}</td>
                  <td>{warrant.witnesses}</td>
                  <td>{warrant.incidentTime}</td>
                  <td>{warrant.incidentDescription}</td>
                  <td>{warrant.evidence}</td>
                  <td>{warrant.suspectStatement}</td>
                  <td>{warrant.witnessStatements}</td>
                  <td>{warrant.status}</td>
                  <td>
                    <button className="edit-button" onClick={() => openModal(warrant, 'arrest')}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {modalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h1>Status ändern</h1>
            <button className="close-button" onClick={closeModal}>X</button>
            <div className="modal-body">
              <p>Sachbearbeiter: {selectedWarrant.officer}</p>
              <p>Aktueller Status: {selectedWarrant.status}</p>
              <div className="status-dropdown">
                <select onChange={handleDropdownChange} defaultValue="">
                  <option value="" disabled>Wählen Sie einen Status</option>
                  <option value="approved">Genehmigt</option>
                  <option value="rejected">Abgelehnt</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="form-buttons">
        <button type="button" className="cancel-button" onClick={() => navigate(-1)}>Zurück</button>
      </div>
    </div>
  );
}

export default WarrantPage;