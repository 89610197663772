import React from 'react';
import { useNavigate } from 'react-router-dom';
import './InteractiveMap.css';

function InteractiveMap() {
  const navigate = useNavigate();

  return (
    <div className="interactive-map-container">
      <button className="back-button" onClick={() => navigate('/swat-fbi')}>
        Zurück
      </button>
      <iframe
        src="https://www.gamermaps.net/map/arma/tanoa"
        title="Tanoa Map"
        style={{ border: 0, width: '100%', height: '100vh' }}
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default InteractiveMap;